
import Vue from "vue";
const { __getText } = Vue.prototype;
import { format } from "date-fns";
import {
  quickpayMapActions,
  quickpayMapMutations,
  quickpayMapState
} from "@/store/modules/quickpay";
import { MessageType } from "element-ui/types/message";
import SimpleNote from "./SimpleNote.vue";
import * as types from "@/store/mutation-types";
import {
  useFormSection,
  useFormFieldGroup,
  useSelectField,
  useTextField,
  useField
} from "@/components/FormBuilder/Helpers";
import { authMapState, authMapGetters } from "@/store/modules/auth";
import { getCompaniesPrefixes } from "@/helpers/companies";
import { omit } from "lodash";
import { getSystemFilesURL } from "@/helpers";
export default Vue.extend({
  components: { SimpleNote },
  name: "PolicyInfo",
  data() {
    return {
      response: {},
      policyInfo: {
        policyNumber: "",
        effectiveDate: new Date(),
        companyPrefix: "",
        insuredEmail: "",
        agency: ""
      },
      policyValidation: {},
      policyPrefixes: []
    };
  },
  props: {
    showSimpleNote: {
      type: Boolean,
      required: false,
      default: true
    },
    isQuickPay: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  async mounted() {
    this.policyInfo.companyPrefix = this.$route.query.prefix as string;
    this.policyInfo.policyNumber = this.$route.query.policyNumber as string;
    this.policyInfo.effectiveDate = this.processEffectiveDate(
      this.$route.query.effectiveDate
    );
    if (
      this.$route.query.prefix &&
      this.$route.query.policyNumber &&
      this.$route.query.effectiveDate
    ) {
      this.makePolicyInquiry();
    }
    this.policyPrefixes = getCompaniesPrefixes("quickPayPolicyInfo");

    if (this.policyPrefixes && this.policyPrefixes.length === 0) {
      const query = this.isQuickPay
        ? {}
        : {
            companyNumber__in: [31, 32]
          };
      let prefixesArray = await this.fetchCompaniesPrefixes({
        excludeOldCompanyPrefixes: "yes",
        ...query
      });
      this.policyPrefixes = prefixesArray.map((prefix: string) => {
        return {
          label: prefix,
          value: prefix
        };
      });
    }
  },
  methods: {
    ...quickpayMapActions([
      "getPolicyInfo",
      "fetchCompaniesPrefixes",
      "verifyInsuredInfo"
    ]),
    ...quickpayMapState(["makingApiRequest"]),
    ...quickpayMapMutations([types.SET_STATE]),
    policyNumberChanged(newNumber: string): void {
      if (/\d+/.test(newNumber)) {
        this.policyInfo.policyNumber = newNumber;
      }
    },
    processEffectiveDate(date: any) {
      return date ? new Date(Date.parse(date)) : new Date();
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    editField(payload: any): void {
      this.SET_STATE(payload);
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      (this.policyInfo as any)[key] = value;
    },
    handleFormValidation($event: any) {
      this.policyValidation = $event;
    },
    async checkEffectiveDate(): Promise<void> {
      let { correctDate: fiservEffectiveDate } = this.response || ({} as any);
      const formattedEffectiveDate = format(
        this.policyInfo.effectiveDate,
        "MM/dd/yyy"
      );
      if (
        fiservEffectiveDate &&
        formattedEffectiveDate != fiservEffectiveDate
      ) {
        await this.confirmConfirmEffectiveDatePrompt(
          "Effective Date entered is invalid for this policy",
          `This is the correct effective date: ${fiservEffectiveDate}. Should this date be used ?`,
          "info",
          fiservEffectiveDate
        );
      } else {
        this.$emit("goto-next", {
          ...this.response,
          insuredEmail: this.policyInfo.insuredEmail
        });
      }
    },
    async confirmConfirmEffectiveDatePrompt(
      title: string,
      message: string,
      type: MessageType,
      value: Date
    ): Promise<any> {
      try {
        await this.$confirm(message, type, {
          title: title,
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          type: type,
          closeOnClickModal: false,
          showClose: false,
          cancelButtonClass:
            "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
        });
        this.editField({ key: "policyInfo.effectiveDate", value });
        this.$emit("goto-next", {
          ...this.response,
          insuredEmail: this.policyInfo.insuredEmail
        });
      } catch (e) {
        //do nothing
      }
    },
    async makePolicyInquiry() {
      try {
        if (!this.policyFormIsValid) {
          return;
        }
        let response;

        if (this.isQuickPay) {
          response = await this.getPolicyInfo({
            ...omit(this.policyInfo, ["agency", "insuredEmail"]),
            policyNumber: parseInt(this.policyInfo.policyNumber)
          });
        } else {
          response = await this.verifyInsuredInfo({
            ...omit(this.policyInfo, ["agency"]),
            policyNumber: parseInt(this.policyInfo.policyNumber)
          });
        }
        this.response = response;
        if (this.response) {
          this.checkEffectiveDate();
        } else {
          this.$appNotify({
            type: "error",
            title: "Error fetching policy info",
            message: "Policy Info could not be fetched"
          });
        }
      } catch (error) {
        const details = {
          title: "Error",
          description: error.message
        };
        this.$emit("showModal", details);
        this.$bugSnagClient.notify(error);
        if (this.isQuickPay) {
          this.$appNotifyError(details.description);
        }
      }
    }
  },
  computed: {
    ...authMapState(["initData"]),
    ...authMapGetters(["isLoggedIn"]),
    policyForm(): any {
      const base = [
        ...useFormSection([
          ...useFormFieldGroup(
            [
              useField({
                key: "effectiveDate",
                label: "Effective Date",
                required: true,
                type: "date",
                pickerOptions: {
                  disabledDate: () => false
                }
              }),
              useSelectField({
                key: "companyPrefix",
                label: "Select Policy Prefix",
                options: this.policyPrefixes,
                required: true,
                filterable: true,
                allowCreate: this.$isCurrentUserAdmin,
                allowFirstOption: true
              }),
              useTextField({
                key: "policyNumber",
                label: "Policy Number",
                placeholder: "Enter Policy Number",
                required: true
              })
            ],
            { layout: "3-col" }
          )
        ])
      ];
      if (!this.isQuickPay) {
        base.push(
          ...useFormSection([
            ...useFormFieldGroup(
              [
                useTextField({
                  key: "insuredEmail",
                  label: "Email",
                  required: false,
                  validations: [
                    {
                      method: "minLength",
                      value: 5,
                      error: __getText("fields", "emailFieldValidateError")
                    }
                  ]
                })
              ],
              { layout: "2-col" }
            )
          ])
        );
      }
      return base;
    },
    getQuickpayPaymentImageUrl() {
      return getSystemFilesURL("quickpay-payment-icons");
    },
    policyFormIsValid(): boolean {
      const { formIsValid } = this.policyValidation as any;
      return formIsValid;
    }
  }
});
