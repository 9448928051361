<template>
  <div class="w-full sm:text-left text-center text-base m-auto mt-8">
    Problems or concerns? <br class="block sm:hidden" />Call Billing at
    <a class="text-atlas-blue font-medium" href="tel:+888-786-0003"
      >888-786-0003</a
    >, option #2
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "simple_note"
});
</script>
