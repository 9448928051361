<template>
  <div class="w-full py-8 lg:px-20 px-4">
    <!-- <div class="text-center text-white ">
      Made with
      <font-awesome-icon :icon="['fal', 'heart']"></font-awesome-icon>
      by
      <router-link to="/">Atlas General Agency </router-link> |
      <router-link to="/contactUs">Contact Us </router-link>
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "quickPayFooter"
});
</script>
