var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],staticClass:"quickpay--main w-screen h-screen flex flex-col sm:py-4",style:(`background-image: url(${_vm.getQuickPayBgURL})`)},[_c('div',{staticClass:"w-full flex justify-center sm:mt-20 p-0"},[_c('div',{staticClass:"bg-white md:w-3/5 max-w-3xl sm:rounded shadow-lg"},[_c('div',{staticClass:"flex justify-between flex-row sm:flex-row px-6 py-6 w-full"},[_vm._m(0),_c('div',{staticClass:"w-48"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"w-full",attrs:{"src":_vm.getLogoPath()}})])],1)]),_c('el-tabs',{staticClass:"mt-2",on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeTabName),callback:function ($$v) {_vm.activeTabName=$$v},expression:"activeTabName"}},[_c('el-tab-pane',{staticClass:"quickpay--tabs__left-pane active",attrs:{"disabled":true,"label":"POLICY INFO","name":"inquiry"}},[_c('PolicyInfo',{staticClass:"p-4 px-8",on:{"goto-next":_vm.gotoNext,"showModal":_vm.handleShowModal}})],1),_c('el-tab-pane',{staticClass:"p-4",attrs:{"disabled":true,"label":"VERIFY POLICY INFO","name":"pay"}},[_c('PaymentInfo',{ref:"paymentTab",staticClass:"p-4 px-8",on:{"gotoPrevious":_vm.gotoPrevious}})],1)],1)],1)]),_c('Footer'),_c('ModalMessage',{attrs:{"name":_vm.modalName,"title":_vm.modalDetails.title,"icon":[
      'fal',
      _vm.modalDetails.icon ? _vm.modalDetails.icon : 'exclamation-circle'
    ],"iconClass":"text-grey-600 text-6xl","description":_vm.modalDetails.description,"showClose":true,"buttons":[
      {
        text: `OK`,
        classList: [''],
        click: () => _vm.$modal.hide(_vm.modalName)
      }
    ],"size":"small"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mb-2 text-2xl sm:text-4xl"},[_c('span',{staticClass:"font-bold"},[_vm._v("QUICK")]),_vm._v(" "),_c('span',[_vm._v("PAY")])]),_c('div',{staticClass:"text-sm"},[_vm._v(" No login required. Just enter policy info to make payment ")])])
}]

export { render, staticRenderFns }