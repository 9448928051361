
import {
  quickpayMapActions,
  quickpayMapMutations,
  quickpayMapState
} from "@/store/modules/quickpay";
import Vue from "vue";
import * as types from "@/store/mutation-types";
import SimpleNote from "./SimpleNote.vue";
import DataView from "@/components/DataView/DataView.vue";
import { get } from "lodash";
import { formatDate } from "@/helpers/dateProcessor";
import { formatCurrencyFloat } from "@/plugins/filters";

import { getActiveSite } from "@/helpers/generalHelpers";

export default Vue.extend({
  components: { SimpleNote, DataView },
  name: "payment-info",
  created() {
    let oneIncPaymentScript = document.createElement("script");
    oneIncPaymentScript.setAttribute("async", "");
    const oneIncBaseURL =
      process.env.VUE_APP_ENV === "production"
        ? "https://portalone.processonepayments.com"
        : "https://stgportalone.processonepayments.com";
    oneIncPaymentScript.setAttribute(
      "src",
      `${oneIncBaseURL}/GenericModalV2/PortalOne.js`
    );
    document.head.appendChild(oneIncPaymentScript);
  },
  data() {
    return {
      showCaution: false,
      paymentOptions: [
        { label: "E-Check ~ Processing Fee Waived", value: "ECheck" },
        { label: "Credit Card ~ Processing Fee - 2.99%", value: "CreditCard" }
      ],
      error: "",
      errorDetails: "",
      container: {} as HTMLElement
    };
  },
  mounted() {
    this.container = document.getElementById(
      "portalOneContainer"
    ) as HTMLElement;
  },
  methods: {
    ...quickpayMapMutations([types.SET_STATE]),
    ...quickpayMapActions(["getSessionId", "saveTransaction"]),
    editField(payload: any): void {
      this.SET_STATE(payload);
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    async pay(): Promise<any> {
      if (this.amountLessThanFive) {
        return this.$appNotify({
          type: "error",
          title: "Current amount due",
          message: "Current amount cannot be less than $5"
        });
      }

      const site = getActiveSite(/*prefix*/);
      const key = await this.getSessionId(site);
      if (key) {
        this.openModalWindow(key);
      } else {
        this.$appNotify({
          type: "error",
          title: "Error creating session key",
          message: "The payment portal loading failed"
        });
      }
    },
    requiredChecks(): boolean {
      const minimumAmountDue = get(this.policyInfo, "currentAmtDue") || 0;
      const totalAmountDue = get(this.policyInfo, "totalAmountDue") || 0;
      let currentAmtDue = get(this.policyInfo, "currentAmtDue") || 0;
      if (currentAmtDue < minimumAmountDue || currentAmtDue > totalAmountDue) {
        this.$appNotifyError(
          "'Amt to Pay' must be equal to or greater than the 'Min Due' and not exceed the 'Pay In Full' amount"
        );

        this.policyInfo.currentAmtDue = minimumAmountDue as any;
        return false;
      }
      return true;
    },
    customFieldValidator() {
      return this.validCurrentAmount;
    },
    subscribeToEvents(container: any): void {
      if (!container) {
        return;
      }
      container.addEventListener("portalOne.load", function() {});
      container.addEventListener("portalOne.unload", () => {
        container.removeEventListener("portalOne.error", (e: any) => {
          const { detail: error } = e;
          const payload = this.getTransactionData(error, "failed");
          this.doSaveTransaction(payload);
        });
        container.removeEventListener(
          "portalOne.paymentComplete",
          (event: CustomEvent) => {
            const response = event.detail;
            this.$notify.success({
              title: "",
              message: `Payment succeeded. You have to wait 2 mins if you want to make another payment on the policy ${this.policyInfo?.policyPrefix} ${this.policyInfo.policyNumber} and with amount $ ${this.policyInfo.currentAmtDue} by ${this.policyInfo.paymentMethod} method`,
              customClass: "quick-pay-notification"
            });
            if (response.transactions && response.transactions.length) {
              for (let transaction of response.transactions) {
                const payload = this.getTransactionData(
                  transaction,
                  "succeeded"
                );
                this.doSaveTransaction(payload);
              }
            } else {
              const payload = this.getTransactionData(response, "succeeded");
              this.doSaveTransaction(payload);
            }
          }
        );
        this.goHome();
      });
      container.addEventListener("portalOne.error", (e: any) => {
        const { detail: error } = e;
        const payload = this.getTransactionData(error, "failed");
        this.doSaveTransaction(payload);
      });
      container.addEventListener(
        "portalOne.paymentComplete",
        (event: CustomEvent) => {
          const response = event.detail;
          this.$notify.success({
            title: "",
            message: `Payment succeeded. You have to wait 2 mins if you want to make another payment on the policy ${this.policyInfo.policyNumber} and with amount ${this.policyInfo.currentAmtDue} by ${this.policyInfo.paymentMethod}`,
            customClass: "quick-pay-notification"
          });
          if (response.transactions && response.transactions.length) {
            for (let transaction of response.transactions) {
              const payload = this.getTransactionData(transaction, "succeeded");
              this.doSaveTransaction(payload);
            }
          } else {
            const payload = this.getTransactionData(response, "succeeded");
            this.doSaveTransaction(payload);
          }
        }
      );
      container.addEventListener("portalOne.paymentCanceled", function() {});
      container.addEventListener("portalOne.saveComplete", function() {});
      container.addEventListener("portalOne.saveCanceled", function() {});
    },
    doSaveTransaction(payload: any) {
      try {
        this.saveTransaction(payload);
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    getTransactionData(response: any, status: string) {
      let payload = {};
      switch (status) {
        case "succeeded":
          payload = {
            companyNumber: this.policyInfo.companyNumber,
            transactionPolicyNumber: get(this.policyInfo, "policyNumber"),
            transactionId: response.transactionId,
            customerName: response.customerName,
            transactionDate: response.transactionDate,
            timeZone: response.timeZone,
            sessionId: response.sessionId,
            paymentCategory:
              response.paymentCategory === "CreditCard" ? "card" : "check",
            lastFourDigits: response.lastFourDigits,
            paymentAmount: response.paymentAmount,
            holderZip: response.holderZip,
            totalAmount: response.totalPaymentAmount,
            convenienceFee: response.convenienceFee,
            clientReferenceData: response.clientReferenceData1,
            cardType: response.cardType,
            cardExpirationYear: response.cardExpirationYear,
            cardExpirationMonth: response.cardExpirationMonth,
            batchNumber: response.batchNumber,
            authCode: response.authCode ? response.authCode : "",
            tokenId: response.tokenId,
            policyPrefix: get(this.policyInfo, "policyPrefix"),
            mode: this.mode,
            transactionDescription:
              response.paymentCategory === "CreditCard" ? "Credit Card" : "ACH",
            status
          };
          break;
        case "failed":
          payload = {
            companyNumber: this.policyInfo.companyNumber,
            transactionPolicyNumber: get(this.policyInfo, "policyNumber"),
            policyPrefix: get(this.policyInfo, "policyPrefix"),
            responseMessage: response.Description,
            paymentAmount: get(this.policyInfo, "currentAmtDue"),
            totalAmount: get(this.policyInfo, "totalAmountDue"),
            transactionDate: new Date(),
            customerName: get(this.policyInfo, "name"),
            paymentCategory:
              get(this.policyInfo, "paymentMethod") === "CreditCard"
                ? "card"
                : "check",
            status
          };
          break;
      }
      return payload;
    },
    openModalWindow(sessionKey: string): void {
      if (this.policyInfo && this.container) {
        //@ts-ignore
        // eslint-disable-next-line no-undef
        const portalOne = new OneInc.PortalOne(
          this.container,
          this.subscribeToEvents
        );
        portalOne.makePayment({
          paymentCategory: this.policyInfo.paymentMethod,
          feeContext: this.feeContext,
          amountContext: "SelectAmount",
          minAmountDue: this.policyInfo.currentAmtDue,
          accountBalance: this.policyInfo.totalAmountDue,
          billingZip: this.policyInfo.zip.Zip,
          billingAddressStreet: this.policyInfo.printAddress1,
          policyHolderName: this.policyInfo.name,
          clientReferenceData1: this.policyInfo.policyNumber,
          confirmationDisplay: "true",
          saveOption: "Save",
          accountGroupCode: this.accountGroupCode,
          acknowledgmentRequired: "true",
          sessionId: sessionKey
        });
      }
    },
    goToPrevious() {
      this.$emit("gotoPrevious");
    },
    formatAmount() {
      if (this.policyInfo && this.policyInfo.currentAmtDue) {
        let amount = parseFloat(this.policyInfo.currentAmtDue).toFixed(2);

        this.editField({ key: "policyInfo.currentAmtDue", value: amount });
      } else {
        let amount = parseFloat("0.00").toFixed(2);
        this.editField({ key: "policyInfo.currentAmtDue", value: amount });
      }
    }
  },
  computed: {
    ...quickpayMapState(["policyInfo", "makingApiRequest"]),
    mode(): string {
      return this.policyInfo &&
        parseFloat(this.policyInfo.totalAmountDue) -
          parseFloat(this.policyInfo.currentAmtDue) ===
          0
        ? "F"
        : "P";
    },
    amountLessThanFive(): boolean {
      if (
        this.policyInfo.companyNumber != "20" &&
        parseFloat(this.policyInfo.currentAmtDue) < 5
      ) {
        return true;
      }
      return false;
    },
    validCurrentAmount(): boolean | null {
      return (
        this.policyInfo &&
        !(
          parseFloat(this.policyInfo.currentAmtDue) <
            parseFloat(this.policyInfo.minAmtDue) ||
          parseFloat(this.policyInfo.currentAmtDue) >
            parseFloat(this.policyInfo.totalAmountDue)
        )
      );
    },
    totalAmountZero(): boolean {
      return this.policyInfo
        ? parseFloat(this.policyInfo.totalAmountDue) === 0 ||
            this.policyInfo.policyStatus === "P"
        : false;
    },
    disableButton(): boolean {
      return (
        this.totalAmountZero ||
        !this.validCurrentAmount ||
        !this.policyInfo?.paymentMethod ||
        !this.showCaution ||
        !this.policyInfo.currentAmtDue ||
        parseFloat(this.policyInfo.currentAmtDue) === 0
      );
    },
    currentAmtDue(): number {
      return parseFloat(this.policyInfo.currentAmtDue);
    },
    feeContext() {
      let paymentfee = "Paymentwithoutfee";
      if (this.policyInfo?.paymentMethod == "CreditCard") {
        paymentfee = "PaymentWithFee";
      }
      return paymentfee;
    },
    accountGroupCode(): number {
      const companyNumber = this.policyInfo.companyNumber;
      let accountGroupCode =
        this.policyInfo && companyNumber ? parseInt(companyNumber) : 0;

      return accountGroupCode;
    },
    getData() {
      let result = {};
      result = {
        sections: [
          {
            sectionTitle: "",
            showDivider: true,
            showSection: true,
            sectionColumnSize: "2",
            information: [
              {
                informationColumnSize: "1",
                key: "Insured Name",
                value: get(this.policyInfo, "name")
              },
              {
                informationColumnSize: "1",
                key: "Policy",
                value: get(this.policyInfo, "policy")
              }
            ]
          },
          {
            sectionTitle: "",
            showDivider: true,
            showSection: true,
            sectionColumnSize: "2",
            information: [
              {
                informationColumnSize: "1",
                key: "Current Due Date",
                value: formatDate(get(this.policyInfo, "currentDueDate"))
              },
              {
                informationColumnSize: "1",
                key: "Minimum Amount Due",
                value: formatCurrencyFloat(get(this.policyInfo, "minAmtDue"))
              }
            ]
          },
          {
            sectionTitle: "",
            showDivider: true,
            showSection: true,
            sectionColumnSize: "2",
            information: [
              {
                informationColumnSize: "1",
                key: "Last Payment",
                value: formatCurrencyFloat(get(this.policyInfo, "lastPayment"))
              },
              {
                informationColumnSize: "1",
                key: "Pay In Full",
                value: formatCurrencyFloat(
                  get(this.policyInfo, "totalAmountDue")
                )
              }
            ]
          }
        ]
      };

      return result;
    }
  }
});
